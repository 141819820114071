<template>
    <LiefengContent>
        <template #title>日访问人数（日活）</template>
        <template #toolsbarRight></template>
        <template #contentArea>
            <!-- <div class="box">
                <div style="font-size:20px">数据来源：微信小程序统计</div>
                <div>
                    <Tooltip content="Here is the prompt text" style="">
                        <Icon color="#336DFF" size="15" type="ios-alert-outline" />
                        <span style="margin-right:20px">数据项解释</span>
                    </Tooltip>
                    <Timedropdown :disabled='disabled' @changeTime='getTime' ></Timedropdown>
                </div>
                
            </div> -->

            <Timeheader @getDate="getDate" :tipText="tipText"></Timeheader> 
            <div class="card">
                <div class="card_heard">
                    <div v-show="showType == 'line'">
                        1月25日——4月28日
                        <span>均值8000</span>
                    </div>
                    <Button :icon="btnIcon" @click="changeType">{{ btnName }}</Button>
                </div>
                <div class="card_content">
                    <Veline v-if="showType == 'line'" :data="linedata" :textStyle="textStyle"></Veline>
                    <Table :loading="tableLoading" v-if="showType == 'table'" :columns="columns1" :data="data1"></Table>
                </div>
            </div>
        </template>
    </LiefengContent>
</template>

<script>
import LiefengContent from "@/components/LiefengContent3"
import LiefengTable from "@/components/LiefengTable"
import Veline from "v-charts/lib/line.common"
import Timedropdown from '../components/timedropdown.vue'
import Timeheader from '../components/timeheader.vue'
export default {
    components: {
        LiefengContent,
        LiefengTable,
        Veline,
        Timedropdown,
        Timeheader
    },
    data() {
        return {
            showType: "line",
            btnName: "切换为表格",
            btnIcon: "ios-grid",
            tableLoading: false,
            searchTime:'',
            tipText:'提示文字提示文字提示文字字提示文字',
            
            linedata: {
                columns: ["日期", "日访问人数"],
                rows: [
                    {
                        日期: "01/25",
                        日访问人数: "800",
                    },
                    {
                        日期: "01/26",
                        日访问人数: "6000",
                    },
                    {
                        日期: "01/27",
                        日访问人数: "8000",
                    },
                    {
                        日期: "01/28",
                        日访问人数: "10000",
                    },
                    {
                        日期: "01/29",
                        日访问人数: "20000",
                    },
                    {
                        日期: "01/30",
                        日访问人数: "24000",
                    },
                    {
                        日期: "01/31",
                        日访问人数: "10000",
                    },
                    {
                        日期: "02/01",
                        日访问人数: "8000",
                    },
                    {
                        日期: "02/02",
                        日访问人数: "8000",
                    },
                    {
                        日期: "02/03",
                        日访问人数: "3000",
                    },
                    {
                        日期: "02/04",
                        日访问人数: "9000",
                    },
                ],
            },
            textStyle: {
                // fontSize:30
            },
            columns1: [
                {
                    title: "序号",
                    key: "no",
                    align: "center",
                },
                {
                    title: "日期",
                    key: "time",
                    align: "center",
                },
                {
                    title: "日访问人数",
                    key: "num",
                    align: "center",
                },
            ],
            data1: [
                {
                    no: 1,
                    time: "2022-01-22",
                    num: 3000,
                },
                {
                    no: 1,
                    time: "2022-01-22",
                    num: 3000,
                },
                {
                    no: 1,
                    time: "2022-01-22",
                    num: 3000,
                },
                {
                    no: 1,
                    time: "2022-01-22",
                    num: 3000,
                },
                {
                    no: 1,
                    time: "2022-01-22",
                    num: 3000,
                },
                {
                    no: 1,
                    time: "2022-01-22",
                    num: 3000,
                },
                {
                    no: 1,
                    time: "2022-01-22",
                    num: 3000,
                },
                {
                    no: 1,
                    time: "2022-01-22",
                    num: 3000,
                },
                {
                    no: 1,
                    time: "2022-01-22",
                    num: 3000,
                },
                {
                    no: 1,
                    time: "2022-01-22",
                    num: 3000,
                },
                {
                    no: 1,
                    time: "2022-01-22",
                    num: 3000,
                },
                {
                    no: 1,
                    time: "2022-01-22",
                    num: 3000,
                },
                {
                    no: 1,
                    time: "2022-01-22",
                    num: 3000,
                },
                {
                    no: 1,
                    time: "2022-01-22",
                    num: 3000,
                },
            ],
        }
    },
    created(){
        this.$Message.loading({
            content: "获取数据中...",
            duration: 0,
        });
        setTimeout(() => {
            this.$Message.destroy();
        },500)
    },
    methods: {
        changeType() {
            if (this.showType == "line") {
                this.btnName = "切换为趋势图"
                this.showType = "table"
                this.btnIcon = "md-trending-up"
                this.tableLoading = true
                let _this = this
                setTimeout(() => {
                    _this.tableLoading = false
                }, 200)
            } else {
                this.btnName = "切换为表格"
                this.showType = "line"
                this.btnIcon = "ios-grid"
            }
        },
        getDate(val){
            console.log(val)
        },
    },
    
    
}
</script>
    
<style scoped lang='less'>
/deep/#contentarea {
    background-color: #f1f1f1;
    .card {
        margin-bottom: 20px;
        width: 100%;
        background: #ffffff;
        border-radius: 10px 10px 10px 10px;
        opacity: 1;
        padding: 20px;
        box-sizing: border-box;
        .card_heard {
            font-size: 20px;
            height: 40px;
            line-height: 40px;
            position: relative;
            display: flex;
            justify-content: space-between;
            .ivu-btn {
                position: absolute;
                right: 0;
                top: 0;
            }
        }
        .ivu-table-header thead tr th {
            background-color: #89abff;
        }
        td.ivu-table-column-center,
        th.ivu-table-column-center {
            background-color: #ecf2ff;
        }
        .ivu-btn:hover {
            color: #336dff;
            background-color: #d9e4ff;
            border-color: #336dff;
        }
        .ivu-btn {
            color: #336dff;
            background-color: #d9e4ff;
            border-color: #d9e4ff;
        }
    }
}


.box{
    margin-top: 15px;
    display: flex;
    justify-content: space-between;
    margin-bottom: 20px;
    width: 100%;
    background: #ffffff;
    border-radius: 10px 10px 10px 10px;
    opacity: 1;
    padding: 20px 68px 20px 20px;
    box-sizing: border-box;
    line-height: 40px;
}
</style>